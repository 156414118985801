import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby-link'

import 'typeface-montserrat'
import 'typeface-sintony'
import '@mdi/font/css/materialdesignicons.min.css'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export class IndexPageTemplate extends Component {
  state = {
    name: '',
    email: '',
    message: '',
    subject: ''
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state
      })
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    const {
      mainImage,
      title,
      mainPitch,
      albums,
      contactForm,
      lang
    } = this.props
    const { name, email, subject, message } = this.state

    return (
      <div className="has-background-black">
        <section
          className="hero is-fullheight"
          style={{
            backgroundImage: `url(${
              mainImage.childImageSharp
                ? mainImage.childImageSharp.fluid.src
                : mainImage
            })`,
            backgroundPosition: `top right`,
            backgroundAttachment: `scroll`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
          }}
        >
          <div className="hero-body center">
            <h1 className="title is-1 is-3-mobile has-text-white">{title}</h1>
          </div>
        </section>
        <section id="about" className="section">
          <div className="columns is-vcentered">
            <div className="column is-1" />
            <div className="column is-7">
              <div className="content">
                <div className="tile">
                  <h3 className="title is-3 has-text-white">
                    {mainPitch.title}
                  </h3>
                </div>
                <div className="tile">
                  <p className="has-text-white-ter">{mainPitch.description}</p>
                </div>
              </div>
            </div>
            <div className="column is-3 is-hidden-mobile">
              <figure className="image is-3by4">
                {mainPitch.sideImage && mainPitch.sideImage.image ? (
                  <img
                    src={
                      mainPitch.sideImage.image.childImageSharp
                        ? mainPitch.sideImage.image.childImageSharp.fluid.src
                        : mainPitch.sideImage.image
                    }
                    alt={mainPitch.sideImage.alt}
                  />
                ) : null}
              </figure>
            </div>
            <div className="column is-1" />
          </div>
        </section>
        <section id="albums" className="section">
          <div className="columns">
            <div className="column is-1" />
            <div className="column">
              <h3 className="title is-3 has-text-white has-extra-padding">
                Albums
              </h3>
            </div>
          </div>
          <div className="columns is-multiline">
            {albums.map(album => (
              <>
                <div className="column is-1" />
                <div className="column is-3 has-extra-margin">
                  <figure className="image is-square album">
                    {album.cover ? (
                      <img
                        src={
                          album.cover.childImageSharp
                            ? album.cover.childImageSharp.fluid.src
                            : album.cover
                        }
                        alt={album.title}
                      />
                    ) : null}
                    <div className="hero is-overlay">
                      <div className="hero-body is-spaced-evenly">
                        <a
                          href={album.apple_music}
                          alt={`Ouvrir l'album "${album.title}" dans Apple Music`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={`/img/${lang}-play_on_apple_music.svg`}
                            alt="Logo Apple Music"
                          />
                        </a>
                        <a
                          href={album.spotify}
                          alt={`Ouvrir l'album "${album.title}" dans Spotify`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            className="open-on-spotify"
                            src="/img/Spotify_Logo_RGB_Green.png"
                            alt="Logo spotify"
                          />
                        </a>
                      </div>
                    </div>
                  </figure>
                </div>
                <div className="column is-2" />
              </>
            ))}
          </div>
        </section>
        <section id="contact" className="section">
          <div className="columns">
            <div className="column is-2" />
            <div className="column is-8">
              <div className="container is-fluid">
                <h3 className="title is-3 has-text-white has-extra-padding">
                  Contact
                </h3>
                <form
                  name="steverumo.com"
                  method="post"
                  action={`/${lang !== 'fr' ? lang + '/' : ''}success`}
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={this.handleSubmit}
                >
                  <div hidden>
                    <label>
                      Don’t fill this out:{' '}
                      <input name="bot-field" onChange={this.onChange} />
                    </label>
                  </div>
                  <div className="field">
                    <label htmlFor="name" className="label has-text-white">
                      {contactForm?.labelName}
                    </label>
                    <div className="control">
                      <input
                        id="name"
                        name="name"
                        className="input"
                        type="text"
                        required
                        value={name}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label htmlFor="email" className="label has-text-white">
                      {contactForm.labelEmail}
                    </label>
                    <div className="control">
                      <input
                        id="email"
                        name="email"
                        className="input"
                        type="email"
                        required
                        value={email}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label htmlFor="subject" className="label has-text-white">
                      {contactForm.labelSubject}
                    </label>
                    <div className="control">
                      <input
                        id="subject"
                        name="subject"
                        className="input"
                        type="text"
                        required
                        value={subject}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label htmlFor="message" className="label has-text-white">
                      {contactForm.labelMessage}
                    </label>
                    <div className="control">
                      <textarea
                        id="message"
                        name="message"
                        className="textarea"
                        required
                        value={message}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="control has-small-extra-padding">
                    <button
                      type="submit"
                      className="button has-background-black has-text-white"
                    >
                      {contactForm.labelSend}
                    </button>
                  </div>
                </form>
                <div className="column is-2" />
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

IndexPageTemplate.propTypes = {
  mainImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  mainPitch: PropTypes.object,
  albums: PropTypes.array
}

export default IndexPageTemplate
